@tailwind base;
@taiwind components;
@taiwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');


html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body input, select{
  border:1px solid #5b21b6;
  border:2px solid rgb(139 92 246 /1);
  height:30px;
  padding: 5px;
  border-radius: 3px;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-aria-Tabs {
  --highlight-color: slateblue;
  --text-color: var(--spectrum-global-color-gray-700);
  --text-color-hover: var(--spectrum-global-color-gray-800);
  --text-color-selected: var(--spectrum-global-color-gray-900);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);

  display: flex;

  &[data-orientation=horizontal] {
    flex-direction: column;
  }

  &[data-orientation=vertical] {
    flex-direction: row;
  }
}

.react-aria-TabList {
  display: flex;

  &[aria-orientation=horizontal] {
    border-bottom: 1px solid gray;

    .react-aria-Tab {
      border-bottom: 3px solid var(--border-color, transparent);
    }
  }

  &[aria-orientation=vertical] {
    flex-direction: column;
    border-right: 1px solid gray;

    .react-aria-Tab {
      border-right: 3px solid var(--border-color, transparent);
    }
  }
}

.react-aria-Tab {
  padding: 10px;
  cursor: default;
  outline: none;
  position: relative;
  color: var(--text-color);
  transition: color 200ms;

  &[data-hovered],
  &:focus {
    color: var(--text-color-hover);
  }

  &[aria-selected=true] {
    --border-color: var(--highlight-color);
    color: var(--text-color-selected);
  }

  &[aria-disabled] {
    color: var(--text-color-disabled);
    &[aria-selected=true] {
      --border-color: var(--text-color-disabled);
    }
  }

  &[data-focus-visible]:after {
    content: '';
    position: absolute;
    inset: 4px;
    border-radius: 4px;
    border: 2px solid var(--highlight-color);
  }
}

.react-aria-TabPanel {
  margin-top: 4px;
  padding: 10px;
  border-radius: 4px;
  outline: none;

  &[data-focus-visible] {
    box-shadow: inset 0 0 0 2px var(--highlight-color);
  }
}

@media (forced-colors: active) {
  .react-aria-Tabs {
    forced-color-adjust: none;
    color: CanvasText;

    --highlight-color: Highlight;
    --text-color: ButtonText;
    --text-color-hover: ButtonText;
    --text-color-selected: ButtonText;
    --text-color-disabled: GrayText;
  }
}